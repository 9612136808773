import React from 'react'
import PropTypes from 'prop-types'
import AccentBorder from '../../elements/AccentBorder'
import metadata from './metadata'
import { IrisImg, IrisCTALinkButton } from '../../../../Shared'
import { getSignupUrl } from '../../../../../utils/url'

const BIMVideo = ({ images, mediaType }) => (
  <div className={`complex-bim ${mediaType}-complex-bim`}>
    <div className="content-container">
      <AccentBorder color="light-blue" />
      <div className="title-container">
        <p className="title">Bring Complex BIM into Virtual Reality</p>
      </div>
      <div className="video-container">
        <div className="video-player-container">
          <div
            className="wistia_responsive_padding"
            style={{ padding: '56.25% 0 0 0', position: 'relative' }}
          >
            <div
              className="wistia_responsive_wrapper"
              style={{
                height: '100%',
                left: 0,
                position: 'absolute',
                top: 0,
                width: '100%',
              }}
            >
              <iframe
                src="https://fast.wistia.net/embed/iframe/1xpvnh9b72?videoFoam=true"
                title="Wistia video player"
                allowTransparency="true"
                frameBorder="0"
                scrolling="no"
                className="wistia_embed"
                name="wistia_embed"
                allowFullScreen
                mozallowfullscreen
                webkitallowfullscreen
                oallowfullscreen
                msallowfullscreen
                width="100%"
                height="100%"
              />
            </div>
          </div>
          <script src="https://fast.wistia.net/assets/external/E-v1.js" async />
        </div>
      </div>
      <div className="features-container">
        {metadata.map(m => (
          <div key={m.text} className="feature-container">
            <div className="icon-container">
              {m.svg && <m.svg style={{ width: '38px' }} />}
              {m.filename && (
                <IrisImg
                  className="icon"
                  file={images[m.filename]}
                  alt={m.text}
                />
              )}
            </div>
            <div className="text-container">
              <p>{m.text}</p>
            </div>
          </div>
        ))}
      </div>
      <div className="button-container">
        <IrisCTALinkButton
          href={getSignupUrl()}
          cta="Start 14-day free trial"
        />
      </div>
    </div>
  </div>
)

BIMVideo.propTypes = {
  mediaType: PropTypes.string.isRequired,
}

export default BIMVideo
