import React from 'react'
import PropTypes from 'prop-types'

const CustomArrow = ({ onClick: libraryOnClick, customOnClick, type }) => (
  <i
    onClick={() => {
      customOnClick()
      libraryOnClick()
    }}
    className={`material-icons ${type}`}
  >
    {`navigate_${type}`}
  </i>
)

CustomArrow.propTypes = {
  onClick: PropTypes.func.isRequired,
  customOnClick: PropTypes.func.isRequired,
  type: PropTypes.string.isRequired,
}

export default CustomArrow
