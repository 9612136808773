import React from 'react'
import fileFormatsHeaderIcon from '../../../../../images/prospect/irisvr-guarantee/load-files.svg'
import headsetsIcon from '../../../../../images/prospect/irisvr-guarantee/vr-headset.svg'
import { Filenames } from '../../filenames'

export default {
  fileFormats: {
    headerTitle: 'file-formats',
    headerText: '3D File Formats',
    headerIcon: fileFormatsHeaderIcon,
    integrations: [
      {
        name: 'Navisworks',
        icon: Filenames.navisworksIcon,
      },
      {
        name: 'Revit',
        icon: Filenames.revitIcon,
      },
      {
        name: 'Rhino',
        icon: Filenames.rhinoIcon,
      },
      {
        name: 'SketchUp',
        icon: Filenames.sketchupIcon,
      },
      {
        name: 'FBX',
        icon: Filenames.fbxIcon,
      },
      {
        name: 'OBJ',
        icon: Filenames.objIcon,
      },
    ],
  },
  headsets: {
    headerTitle: 'vr-headsets',
    headerText: 'VR Headsets',
    headerIcon: headsetsIcon,
    integrations: [
      {
        name: 'HTC Vive',
        icon: Filenames.htcIcon,
      },
      {
        name: (
          <div>
            <p>Oculus Rift</p>
            <p>Meta Quest 2</p>
          </div>
        ),
        icon: Filenames.oculusIcon,
      },
      {
        name: 'Windows MR VR',
        icon: Filenames.windowsMrIcon,
      },
    ],
  },
}
