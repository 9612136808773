import React, { Component } from 'react'
import PropTypes from 'prop-types'
import GuestViewerAsterisk from '../GuestViewerAsterisk'

class FeatureListContainer extends Component {
  state = {
    currIndex: 0,
    numFeatures: this.props.features.length,
    activeFeature: this.props.features[0].name,
    setIntervalId: undefined,
  }

  componentDidMount() {
    // Begin rotating through features onmount
    this.rotateFeature()
  }

  componentWillReceiveProps(nextProps) {
    // This should trigger if someone clicks on the arrow
    // button in GifSlideshowContainer
    const manuallyTriggered = !this.props.actionType && nextProps.actionType
    if (manuallyTriggered) {
      this.selectAdjacentFeature(nextProps.actionType)
    }
  }

  rotateFeature = () => {
    const setIntervalId = setInterval(() => {
      this.selectAdjacentFeature('next', true)
    }, this.props.autoplaySpeed)
    this.setState({ setIntervalId })
  }

  // Manually select from feature list
  clickFeature = activeFeature => {
    this.pause()
    this.setState({
      activeFeature,
      currIndex: this.props.features.findIndex(f => f.name === activeFeature),
    })
  }

  selectAdjacentFeature = (type, isAuto) => {
    if (!isAuto) this.pause() // Pause on selection, unless in autoplay
    const { currIndex, numFeatures } = this.state
    const { features, selectIndex } = this.props
    const inc = type === 'next' ? 1 : -1
    let nextIndex = (currIndex + inc) % numFeatures
    if (nextIndex < 0) nextIndex = numFeatures - 1
    const nextFeature = features[nextIndex].name
    selectIndex(nextIndex)
    this.setState({
      activeFeature: nextFeature,
      currIndex: nextIndex,
    })
  }

  pause = () => clearInterval(this.state.setIntervalId)

  render() {
    const { title, description, features, selectIndex, mediaType } = this.props
    const { activeFeature } = this.state

    return (
      <div
        className={`feature-list-container ${mediaType}-feature-list-container`}
      >
        <div className="list-header-container">
          <p className="list-header-title">{title}</p>
          <p className="list-header-desc">{description}</p>
        </div>
        <div className="feature-list-elements-container">
          {features.map((feature, i) => {
            const isActiveClassName =
              activeFeature === feature.name ? 'isActive' : ''
            const svgFillColor =
              activeFeature === feature.name ? '#4D9DE3' : '#BDBDBD'
            return (
              <div
                key={feature.name}
                className={`feature-container ${isActiveClassName}`}
                onClick={() => {
                  selectIndex(i)
                  this.clickFeature(feature.name)
                }}
              >
                <div className="feature-icon-container">
                  <feature.icon fill={svgFillColor} />
                </div>
                <p className="feature-name">{feature.name}</p>
              </div>
            )
          })}
        </div>
        {title === 'Collaboration' && mediaType === 'desktop' && (
          <GuestViewerAsterisk />
        )}
        {
          // Disabled until we have more features down the line
          // <div className="list-footer-container">
          //   <a
          //     href="https://google.com"
          //     target="_blank"
          //     rel="noopener noreferrer"
          //   >See more features</a>
          // </div>
        }
      </div>
    )
  }
}

FeatureListContainer.propTypes = {
  selectIndex: PropTypes.func.isRequired,
  title: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  features: PropTypes.arrayOf(PropTypes.object).isRequired,
  autoplaySpeed: PropTypes.number.isRequired,
  actionType: PropTypes.string.isRequired,
  mediaType: PropTypes.string.isRequired,
}

export default FeatureListContainer
