import React from 'react'
import PropTypes from 'prop-types'
import AccentBorder from '../../elements/AccentBorder'
import { LogoBanner } from '../../../../Shared'

const Clients = ({ logoBannerImages, mediaType }) => (
  <div className={`clients-section ${mediaType}-clients-section`}>
    <div className="content-container">
      <AccentBorder color="navy" />
      <div className="title-container">
        <p className="title">You’re In Good Company</p>
      </div>
      <div className="subtitle-container">
        <p className="subtitle">
          We’re building software for AEC firms just like yours.
        </p>
      </div>
      <LogoBanner
        images={logoBannerImages}
        type="trusted"
        largeWidth={mediaType === 'desktop'}
      />
    </div>
  </div>
)

Clients.propTypes = {
  mediaType: PropTypes.string.isRequired,
}

export default Clients
