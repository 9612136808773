import React from 'react'
import PropTypes from 'prop-types'

const ScreenshotIcon = ({ fill }) => (
  <svg
    width="15px"
    height="14px"
    viewBox="0 0 15 14"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
  >
    <desc>Created with Sketch.</desc>
    <defs />
    <g
      id="Prospect-Prod-Page"
      stroke="none"
      strokeWidth="1"
      fill={fill}
      fillRule="evenodd"
    >
      <g
        id="Prospect-Product-Page_Desktop"
        transform="translate(-275.000000, -4673.000000)"
        fill={fill}
        fillRule="nonzero"
      >
        <g id="Group-21" transform="translate(275.000000, 4673.000000)">
          <g id="Group-18">
            <ellipse
              id="Oval"
              cx="7.5"
              cy="7.63636364"
              rx="2.5"
              ry="2.54545455"
            />
            <path
              d="M5.25,0 L3.8775,1.55555556 L1.5,1.55555556 C0.675,1.55555556 0,2.25555556 0,3.11111111 L0,12.4444444 C0,13.3 0.675,14 1.5,14 L13.5,14 C14.325,14 15,13.3 15,12.4444444 L15,3.11111111 C15,2.25555556 14.325,1.55555556 13.5,1.55555556 L11.1225,1.55555556 L9.75,0 L5.25,0 Z M7.5,11.6666667 C5.43,11.6666667 3.75,9.92444444 3.75,7.77777778 C3.75,5.63111111 5.43,3.88888889 7.5,3.88888889 C9.57,3.88888889 11.25,5.63111111 11.25,7.77777778 C11.25,9.92444444 9.57,11.6666667 7.5,11.6666667 Z"
              id="Shape"
            />
          </g>
        </g>
      </g>
    </g>
  </svg>
)

ScreenshotIcon.propTypes = {
  fill: PropTypes.string.isRequired,
}

export default ScreenshotIcon
