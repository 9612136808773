import React, { Component } from 'react'
import PropTypes from 'prop-types'
import DesktopSplash from './DesktopSplash'
import MobileSplash from './MobileSplash'

class SplashContainer extends Component {
  state = {
    views: {
      mobile: MobileSplash,
      tablet: MobileSplash,
      laptop: DesktopSplash,
      desktop: DesktopSplash,
    },
  }

  render() {
    const { views } = this.state
    const { mediaType } = this.props
    const View = views[mediaType]
    return (
      <div className="prospect-splash">
        <View />
      </div>
    )
  }
}

SplashContainer.propTypes = {
  mediaType: PropTypes.string.isRequired,
}

export default SplashContainer
