import React from 'react'
import PropTypes from 'prop-types'
import AccentBorder from '../../elements/AccentBorder'
import FeaturesContainer from './FeaturesContainer'
import metadata from './metadata'

const Features = ({ width, mediaType }) => (
  <div>
    <FeaturesContainer
      mediaType={mediaType}
      category="design-review"
      content={metadata.designReview}
      width={width}
    />
    <FeaturesContainer
      mediaType={mediaType}
      category="collaboration"
      content={metadata.collaboration}
      width={width}
    />
  </div>
)

Features.propTypes = {
  mediaType: PropTypes.string.isRequired,
  width: PropTypes.number.isRequired,
}

const VRTools = ({ mediaType, width }) => (
  <div id="prospect-features" className={`vr-tools ${mediaType}-vr-tools`}>
    <div className="content-container">
      <AccentBorder color="light-blue" />
      <div className="title-container">
        <p className="title">
          Prospect features for design review & collaboration in VR
        </p>
      </div>
      {mediaType === 'mobile' ||
      mediaType === 'tablet' ||
      mediaType === 'laptop' ? (
        ''
      ) : (
        <Features mediaType={mediaType} width={width} />
      )}
    </div>
    {mediaType === 'mobile' ||
    mediaType === 'tablet' ||
    mediaType === 'laptop' ? (
      <div className="features-section-container">
        <Features mediaType={mediaType} width={width} />
      </div>
    ) : (
      ''
    )}
  </div>
)

VRTools.propTypes = {
  mediaType: PropTypes.string.isRequired,
  width: PropTypes.number.isRequired,
}

export default VRTools
