import React from 'react'
import PropTypes from 'prop-types'

const TapeIcon = ({ fill }) => (
  <svg
    width="21px"
    height="16px"
    viewBox="0 0 21 16"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
  >
    <desc>Created with Sketch.</desc>
    <defs />
    <g
      id="Prospect-Prod-Page"
      stroke="none"
      strokeWidth="1"
      fill={fill}
      fillRule="evenodd"
    >
      <g
        id="Prospect-Product-Page_Desktop"
        transform="translate(-707.000000, -3932.000000)"
        fill={fill}
        fillRule="nonzero"
      >
        <path
          d="M727.00117,3941.99734 C726.32623,3941.99734 725.977984,3942.55783 725.977984,3943.00615 L725.977984,3945.00322 L722.719838,3945.00322 L722.719838,3940.40776 C722.729472,3940.25842 722.735705,3940.10907 722.735705,3939.95944 C722.735705,3935.56393 719.210012,3932 714.867994,3932 C710.519743,3932 707,3935.56393 707,3939.95944 C707,3940.21914 707.009634,3940.47914 707.035418,3940.73254 L707.035418,3946.05077 C707.035418,3947.126 707.899628,3948 708.965582,3948 L721.01298,3948 C721.844888,3948 722.338102,3947.7048 722.607849,3946.96094 L727.00117,3946.98043 C727.045939,3946.98043 727.091274,3946.97384 727.132926,3946.96094 C727.515162,3946.89587 728,3946.36611 728,3945.96337 L728,3943.00615 C728.000283,3942.55754 727.67611,3941.99734 727.00117,3941.99734 Z M714.757973,3945.0786 C712.150894,3945.0786 710.041036,3942.94386 710.041036,3940.30667 C710.041036,3937.67256 712.150894,3935.53474 714.757973,3935.53474 C717.361999,3935.53474 719.474909,3937.67256 719.474909,3940.30667 C719.474909,3942.94386 717.361999,3945.0786 714.757973,3945.0786 Z"
          id="Shape"
        />
      </g>
    </g>
  </svg>
)

TapeIcon.propTypes = {
  fill: PropTypes.string.isRequired,
}

export default TapeIcon
