import React from 'react'
import PropTypes from 'prop-types'

const SunIcon = ({ fill }) => (
  <svg
    width="20px"
    height="19px"
    viewBox="0 0 20 19"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
  >
    <desc>Created with Sketch.</desc>
    <defs />
    <g
      id="Prospect-Prod-Page"
      stroke="none"
      strokeWidth="1"
      fill="none"
      fillRule="evenodd"
    >
      <g
        id="Prospect-Product-Page_Desktop"
        transform="translate(-697.000000, -4029.000000)"
      >
        <g
          id="baseline-wb_sunny-24px"
          transform="translate(697.000000, 4029.000000)"
        >
          <polygon id="Shape" points="0 0 20 0 20 20 0 20" />
          <path
            d="M5.66666667,4 L4.16666667,2.58333333 L3,3.75 L4.5,5.25 L5.66666667,4 Z M3.33333333,8.75 L0.833333333,8.75 L0.833333333,10.4166667 L3.33333333,10.4166667 L3.33333333,8.75 Z M10.8333333,0.5 L9.16666667,0.5 L9.16666667,2.91666667 L10.8333333,2.91666667 C10.8333333,2.91666667 10.8333333,0.5 10.8333333,0.5 Z M17.0833333,3.75 L15.8333333,2.58333333 L14.3333333,4.08333333 L15.5,5.25 L17.0833333,3.75 Z M14.3333333,15.1666667 L15.8333333,16.6666667 L17,15.5 L15.5,14 C15.5,14 14.3333333,15.1666667 14.3333333,15.1666667 Z M16.6666667,8.75 L16.6666667,10.4166667 L19.1666667,10.4166667 L19.1666667,8.75 L16.6666667,8.75 Z M10,4.58333333 C7.25,4.58333333 5,6.83333333 5,9.58333333 C5,12.3333333 7.25,14.5833333 10,14.5833333 C12.75,14.5833333 15,12.3333333 15,9.58333333 C15,6.83333333 12.75,4.58333333 10,4.58333333 Z M9.16666667,18.75 L10.8333333,18.75 L10.8333333,16.25 L9.16666667,16.25 L9.16666667,18.75 Z M3,15.4166667 L4.16666667,16.6666667 L5.66666667,15.1666667 L4.5,14 L3,15.4166667 Z"
            id="Shape"
            fill={fill}
            fillRule="nonzero"
          />
        </g>
      </g>
    </g>
  </svg>
)

SunIcon.propTypes = {
  fill: PropTypes.string.isRequired,
}

export default SunIcon
