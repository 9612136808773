import React from 'react'
import PropTypes from 'prop-types'
import AccentBorder from '../../elements/AccentBorder'
import { IrisCTALinkButton } from '../../../../Shared'
import Integrations from './Integrations'
import metadata from './metadata'
import { getSignupUrl } from '../../../../../utils/url'

const IntegrationsSection = ({ images, mediaType }) => (
  <div className={`integrations-section ${mediaType}-integrations-section`}>
    <div className="content-container">
      <AccentBorder color="light-blue" />
      <div className="title-container">
        <p className="title">Seamlessly integrated into your workflow</p>
      </div>
      <div className="integrations-container">
        <Integrations
          headerIconName="files"
          images={images}
          {...metadata.fileFormats}
        />
        <Integrations
          headerIconName="vr-headsets"
          images={images}
          {...metadata.headsets}
        />
      </div>
      <div className="button-container">
        <IrisCTALinkButton href={getSignupUrl()} cta="Try Prospect now" />
      </div>
    </div>
  </div>
)

IntegrationsSection.propTypes = {
  mediaType: PropTypes.string.isRequired,
}

export default IntegrationsSection
