import React from 'react'
import AccentBorder from '../../../elements/AccentBorder'
import { IrisCTALinkButton } from '../../../../../Shared'
import { getSignupUrl } from '../../../../../../utils/url'

const MobileSplash = () => (
  <div className="mobile-splash-content-container">
    <div className="splash-image-container" />
    <div className="text-content-container">
      <div className="content-container">
        <div className="text-content">
          <AccentBorder color="light-blue" />
          <div className="title-container">
            <p className="title">Instant 3D to VR</p>
          </div>
          <div className="desc-container">
            <p className="desc">Communicate Design Intent in 1:1 Scale</p>
          </div>
          <div className="button-container">
            <IrisCTALinkButton href={getSignupUrl()} cta="Free Trial" />
          </div>
        </div>
      </div>
    </div>
  </div>
)

export default MobileSplash
