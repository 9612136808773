import React from 'react'
import PropTypes from 'prop-types'

const OverlayIcon = ({ fill }) => (
  <svg
    width="18px"
    height="15px"
    viewBox="0 0 18 15"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
  >
    <desc>Created with Sketch.</desc>
    <defs />
    <g
      id="Prospect-Prod-Page"
      stroke="none"
      strokeWidth="1"
      fill={fill}
      fillRule="evenodd"
    >
      <g
        id="Prospect-Product-Page_Desktop"
        transform="translate(-274.000000, -4576.000000)"
        fill={fill}
      >
        <g id="Group-6" transform="translate(274.000000, 4576.000000)">
          <rect id="Rectangle-4" x="0" y="0" width="2.25" height="2" />
          <rect id="Rectangle-4" x="0" y="6" width="2.25" height="2" />
          <rect id="Rectangle-4" x="0" y="13" width="2.25" height="2" />
          <rect id="Rectangle-4" x="5.625" y="0" width="12.375" height="2" />
          <rect id="Rectangle-4" x="5.625" y="6" width="12.375" height="2" />
          <rect id="Rectangle-4" x="5.625" y="13" width="12.375" height="2" />
        </g>
      </g>
    </g>
  </svg>
)

OverlayIcon.propTypes = {
  fill: PropTypes.string.isRequired,
}

export default OverlayIcon
