import React from 'react'
import PropTypes from 'prop-types'
import AccentBorder from '../../elements/AccentBorder'
import FeatureContainer from './FeatureContainer'
import metadata from './metadata'
import { IrisImg } from '../../../../Shared'
import { Filenames } from '../../filenames'

const ImageContainer = ({ image }) => (
  <div className="image-container">
    <IrisImg file={image} className="guarantee-image" alt="Guarantee" />
  </div>
)

const IrisVRGuarantee = ({ images, mediaType }) => {
  const imageContainer = <ImageContainer image={images[Filenames.guarantee]} />
  return (
    <div
      className={`irisvr-guarantee-section ${mediaType}-irisvr-guarantee-section`}
    >
      <div className="content-container">
        {(mediaType === 'mobile' || mediaType === 'tablet') && imageContainer}
        <AccentBorder color="light-blue" />
        <div className="title-container">
          <p className="title">Why IrisVR?</p>
        </div>
        <div className="subtitle-container">
          <p className="subtitle">
            We build VR-first—that means we don’t compromise on comfort, ease of
            use, or performance.
          </p>
        </div>
        <div className="main-content-container">
          <div className="features-container">
            {metadata.map(f => (
              <FeatureContainer images={images} key={f.title} {...f} />
            ))}
          </div>
          {mediaType !== 'mobile' && mediaType !== 'tablet' && imageContainer}
        </div>
      </div>
    </div>
  )
}

IrisVRGuarantee.propTypes = {
  mediaType: PropTypes.string.isRequired,
}

export default IrisVRGuarantee
