import React from 'react'
import PropTypes from 'prop-types'
import './Testimonial.global.scss'
import { IrisImg } from '../../../../Shared'
import { Filenames } from '../../filenames'

const Testimonial = ({
  children,
  images,
  companyLogo,
  companyName,
  author,
  authorTitle,
  mediaType,
}) => (
  <div
    className={`prospect-splash-testimonial ${mediaType}-prospect-splash-testimonial`}
  >
    <div className="open-quote-container">
      <IrisImg
        className="quote-icon"
        file={images[Filenames.openQuote]}
        alt="Open quotation mark"
      />
    </div>
    <div className="testimonial-content-container">
      <div className="text-content-container">
        <div className="text-content">{children}</div>
      </div>
      <div className="footer-content-container">
        <div className="logo-container">
          <IrisImg file={images[companyLogo]} alt={companyName} />
        </div>
        <div className="author-container">
          <p className="author">{author}</p>
          <p className="author-title">
            {authorTitle}, {companyName}
          </p>
        </div>
      </div>
    </div>
    <div className="close-quote-container">
      <IrisImg
        className="quote-icon"
        file={images[Filenames.closedQuote]}
        alt="Closed quotation mark"
      />
    </div>
  </div>
)

Testimonial.propTypes = {
  children: PropTypes.element.isRequired,
  companyLogo: PropTypes.string.isRequired,
  companyName: PropTypes.string.isRequired,
  author: PropTypes.string.isRequired,
  authorTitle: PropTypes.string.isRequired,
  mediaType: PropTypes.string.isRequired,
}

export default Testimonial
