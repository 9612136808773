import React from 'react'
import PropTypes from 'prop-types'

const GuestViewerIcon = ({ fill }) => (
  <svg
    width="18px"
    height="21px"
    viewBox="0 0 18 21"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
  >
    <desc>Created with Sketch.</desc>
    <defs />
    <g
      id="Prospect-Prod-Page"
      stroke="none"
      strokeWidth="1"
      fill={fill}
      fillRule="evenodd"
    >
      <g
        id="Prospect-Product-Page_Desktop"
        transform="translate(-274.000000, -4720.000000)"
        fill={fill}
        stroke={fill}
        strokeWidth="0.5"
      >
        <path
          d="M276.332667,4726.46841 L283.316273,4722.43674 L289.915841,4726.2463 L283.096311,4730.49221 C282.974508,4730.56816 282.899993,4730.70142 282.899993,4730.84544 L282.899993,4738.32342 L276.332667,4734.53177 L276.332667,4726.46841 Z M290.299878,4734.53177 L283.733269,4738.32342 L283.733269,4731.07686 L290.299878,4726.9893 L290.299878,4734.53177 Z M291.300095,4725.50617 L283.650156,4721.08974 C283.443808,4720.97009 283.189454,4720.97009 282.983106,4721.08974 L275.33245,4725.50617 C275.126818,4725.62511 275,4725.84507 275,4726.08366 L275,4734.91724 C275,4735.15511 275.126818,4735.37507 275.33245,4735.49401 L282.983106,4739.91116 C283.08628,4739.97062 283.201635,4740 283.316273,4740 C283.431627,4740 283.546265,4739.97062 283.650156,4739.91116 L291.300095,4735.49401 C291.506444,4735.37507 291.632546,4735.15511 291.632546,4734.91724 L291.632546,4726.08366 C291.632546,4725.84507 291.506444,4725.62511 291.300095,4725.50617 Z"
          id="Fill-1"
        />
      </g>
    </g>
  </svg>
)

GuestViewerIcon.propTypes = {
  fill: PropTypes.string.isRequired,
}

export default GuestViewerIcon
