import React from 'react'
import { Link } from 'gatsby'

const GuestViewerAsterisk = () => (
  <div className="guest-viewer-asterisk-container">
    <p className="guest-viewer-asterisk">
      *Guests can join multiuser meetings without an account.{' '}
      <Link to="/prospect-viewer">Learn More</Link>
    </p>
  </div>
)

export default GuestViewerAsterisk
