import React from 'react'
import PropTypes from 'prop-types'

const LayersIcon = ({ fill }) => (
  <svg
    width="18px"
    height="20px"
    viewBox="0 0 18 20"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
  >
    <desc>Created with Sketch.</desc>
    <defs />
    <g
      id="Prospect-Prod-Page"
      stroke="none"
      strokeWidth="1"
      fill="none"
      fillRule="evenodd"
    >
      <g
        id="Prospect-Product-Page_Desktop"
        transform="translate(-697.000000, -3833.000000)"
      >
        <g
          id="baseline-layers-24px-(1)"
          transform="translate(694.000000, 3831.000000)"
        >
          <polygon id="Shape" points="0 0 24 0 24 24 0 24" />
          <path
            d="M12,18.5 L4.6,12.8 L3,14.1 L12,21.1 L21,14.1 L19.4,12.8 C19.4,12.8 12,18.5 12,18.5 Z M12,16 L19.4,10.3 L21,9 L12,2 L3,9 L4.6,10.3 L12,16 Z"
            id="Shape"
            fill={fill}
            fillRule="nonzero"
          />
        </g>
      </g>
    </g>
  </svg>
)

LayersIcon.propTypes = {
  fill: PropTypes.string.isRequired,
}

export default LayersIcon
