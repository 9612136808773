import React from 'react'
import PropTypes from 'prop-types'

const InspectIcon = ({ fill }) => (
  <svg
    width="21px"
    height="19px"
    viewBox="0 0 21 19"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
  >
    <desc>Created with Sketch.</desc>
    <defs />
    <g
      id="Prospect-Prod-Page"
      stroke="none"
      strokeWidth="1"
      fill={fill}
      fillRule="evenodd"
    >
      <g
        id="Prospect-Product-Page_Desktop"
        transform="translate(-698.000000, -3882.000000)"
        fill={fill}
      >
        <g id="Group-14" transform="translate(698.000000, 3883.000000)">
          <path
            d="M9.81675425,6.86491684 C10.394729,9.64744147 13.0036702,11.7474505 16.1347118,11.7474505 C16.333169,11.7474505 16.5295288,11.7390137 16.7234068,11.7225011 L16.7234068,13.1506071 C16.7234068,13.3443694 16.6114586,13.5206877 16.4361016,13.6031122 L8.30109021,17.4268809 L8.27659322,7.60709715 L9.81675425,6.86491684 Z"
            id="Combined-Shape"
          />
          <path
            d="M19.535728,1.4646706 C20.4725945,2.39784362 21,3.67120816 21,5 C21,6.32879184 20.4725945,7.60215638 19.535728,8.5353294 C17.5753128,10.4882235 14.4246872,10.4882235 12.464272,8.5353294 C11.5274055,7.60215638 11,6.32879184 11,5 C11,3.67120816 11.5274055,2.39784362 12.464272,1.4646706 C14.4246872,-0.488223532 17.5753128,-0.488223532 19.535728,1.4646706 Z M16.4787879,7.64444444 L16.4909091,3.95555556 L15.4929293,3.95555556 L15.4929293,7.64444444 L16.4787879,7.64444444 Z M16.3979798,2.82828283 C16.4984328,2.73296986 16.5529145,2.59897432 16.5474747,2.46060606 C16.5507036,2.32300538 16.4948069,2.19061857 16.3939394,2.0969697 C16.1572402,1.90937883 15.8225578,1.90937883 15.5858586,2.0969697 C15.4864806,2.19133733 15.4320944,2.32362791 15.4363636,2.46060606 C15.4319619,2.59960152 15.4879568,2.73369472 15.589899,2.82828283 C15.8255353,3.01890186 16.1623435,3.01890186 16.3979798,2.82828283 Z"
            id="Combined-Shape"
          />
          <polygon
            id="Rectangle-3"
            transform="translate(3.000000, 11.000000) scale(1, -1) rotate(90.000000) translate(-3.000000, -11.000000) "
            points="-3 8 6.10869966 8 9 14 -0.10869966 14"
          />
          <path
            d="M11.0316256,0.805932377 C9.86216695,1.84611144 9.13241132,3.31980247 9.13241132,4.95394939 C9.13241132,5.04842102 9.13485025,5.1423564 9.13967205,5.23570275 L6.93876083,6.18981964 L1.42230467,3.20549611 L9.15922482,-0.18981964 L11.0316256,0.805932377 Z"
            id="Combined-Shape"
          />
        </g>
      </g>
    </g>
  </svg>
)

InspectIcon.propTypes = {
  fill: PropTypes.string.isRequired,
}

export default InspectIcon
