import React from 'react'
import PropTypes from 'prop-types'
import helmetProps from './helmetProps'
import breakpoints from './breakpoints'
import { Layout } from '../../Shared'

// Sections
import SplashContainer from './sections/SplashContainer'
import BIMVideo from './sections/BIMVideo'
import FirstTestimonial from './sections/FirstTestimonial'
import VRTools from './sections/VRTools'
import IntegrationsSection from './sections/IntegrationsSection'
import Clients from './sections/Clients'
import SecondTestimonial from './sections/SecondTestimonial'
import IrisVRGuarantee from './sections/IrisVRGuarantee'
import Upgrade from './sections/Upgrade'
import { Filenames } from './filenames'

// Styles
import './Prospect.global.scss'

class Prospect extends React.Component {
  render() {
    const { width, widthClass, images, logoBannerImages } = this.props
    return (
      <div id="prospect" className={`prospect-${widthClass}`}>
        <SplashContainer images={images} mediaType={widthClass} />
        <BIMVideo images={images} mediaType={widthClass} />
        {/* <FirstTestimonial images={images} mediaType={widthClass} /> */}
        <VRTools mediaType={widthClass} width={width} />
        <IntegrationsSection images={images} mediaType={widthClass} />
        <Clients logoBannerImages={logoBannerImages} mediaType={widthClass} />
        <SecondTestimonial images={images} mediaType={widthClass} />
        <IrisVRGuarantee images={images} mediaType={widthClass} />
        <Upgrade
          logo={images[Filenames.startTrialProspectLogo]}
          mediaType={widthClass}
        />
      </div>
    )
  }
}

Prospect.propTypes = {
  widthClass: PropTypes.string.isRequired,
}

export default props => (
  <Layout breakpoints={breakpoints}>
    <Prospect {...props} />
  </Layout>
)

export { helmetProps }
