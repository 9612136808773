import React from 'react'
import PropTypes from 'prop-types'

const SunIcon = ({ fill }) => (
  <svg
    width="14px"
    height="20px"
    viewBox="0 0 14 20"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
  >
    <desc>Created with Sketch.</desc>
    <defs />
    <g
      id="Prospect-Prod-Page"
      stroke="none"
      strokeWidth="1"
      fill={fill}
      fillRule="evenodd"
    >
      <g
        id="Prospect-Product-Page_Desktop"
        transform="translate(-282.000000, -4621.000000)"
        fill={fill}
        fillRule="nonzero"
      >
        <path
          d="M289,4621 C285.13,4621 282,4624.13 282,4628 C282,4633.25 289,4641 289,4641 C289,4641 296,4633.25 296,4628 C296,4624.13 292.87,4621 289,4621 Z M289,4630.5 C287.62,4630.5 286.5,4629.38 286.5,4628 C286.5,4626.62 287.62,4625.5 289,4625.5 C290.38,4625.5 291.5,4626.62 291.5,4628 C291.5,4629.38 290.38,4630.5 289,4630.5 Z"
          id="Shape"
        />
      </g>
    </g>
  </svg>
)

SunIcon.propTypes = {
  fill: PropTypes.string.isRequired,
}

export default SunIcon
