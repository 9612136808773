import React from 'react'
import PropTypes from 'prop-types'

const NoHmdIcon = ({ fill }) => (
  <svg
    width="20px"
    height="19px"
    viewBox="0 0 20 19"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
  >
    <desc>Created with Sketch.</desc>
    <defs />
    <g
      id="Prospect-Prod-Page"
      stroke="none"
      strokeWidth="1"
      fill={fill}
      fillRule="evenodd"
    >
      <g
        id="Prospect-Product-Page_Desktop"
        transform="translate(-272.000000, -4524.000000)"
        fill={fill}
      >
        <path
          d="M290.181818,4524 L273.818182,4524 C272.809091,4524 272,4524.80909 272,4525.81818 L272,4536.72727 C272,4537.73091 272.814545,4538.54545 273.818182,4538.54545 L280.181818,4538.54545 L280.181818,4540.36364 L278.363636,4540.36364 L278.363636,4542.18182 L285.636364,4542.18182 L285.636364,4540.36364 L283.818182,4540.36364 L283.818182,4538.54545 L290.181818,4538.54545 C291.185455,4538.54545 292,4537.73091 292,4536.72727 L292,4525.81818 C292,4524.80909 291.181818,4524 290.181818,4524 M290.181818,4536.72727 L273.818182,4536.72727 L273.818182,4525.81818 L290.181818,4525.81818"
          id="Fill-1"
        />
      </g>
    </g>
  </svg>
)

NoHmdIcon.propTypes = {
  fill: PropTypes.string.isRequired,
}

export default NoHmdIcon
