import React from 'react'
import PropTypes from 'prop-types'
import { IrisImg } from '../../../../../Shared'
import { Link } from 'gatsby'

const IntegrationIconContainer = props => {
  const { integration: i, images } = props

  return (
    <div key={i.name} className="integration-container">
      <div className="integration-icon-container">
        <IrisImg
          className={`integration-icon integration-${i.name}-icon`}
          file={images[i.icon]}
          alt={i.name}
        />
      </div>
      <div className="integration-name-container">
        <p className="integration-name">{i.name}</p>
      </div>
    </div>
  )
}

const LinkedIntegrationIconContainer = props => {
  const name = props.integration.name
  const headerTitle = props.headerTitle
  const integrationURLName = () => {
    if (typeof name === 'string') {
      return name.toLowerCase()
    }
  }
  const linkedIntegration = [
    'navisworks',
    'revit',
    'rhino',
    'sketchup',
  ].includes(integrationURLName())

  const linkedHeadset = headerTitle === 'vr-headsets'

  if (linkedIntegration) {
    return (
      <Link
        to={`/integrations/${integrationURLName()}`}
        style={{ textDecoration: 'none' }}
      >
        <IntegrationIconContainer
          integration={props.integration}
          images={props.images}
        />
      </Link>
    )
  }

  if (linkedHeadset) {
    return (
      <Link to={`/supported-headsets`} style={{ textDecoration: 'none' }}>
        <IntegrationIconContainer
          integration={props.integration}
          images={props.images}
        />
      </Link>
    )
  }

  return (
    <IntegrationIconContainer
      integration={props.integration}
      images={props.images}
    />
  )
}

const HeaderText = ({ headerTitle, headerText }) => {
  const linkedHeaderText = headerTitle === 'vr-headsets'

  if (linkedHeaderText) {
    return (
      <div style={{ marginTop: 20, marginBottom: 20 }}>
        <Link
          to={'/supported-headsets'}
          style={{
            fontFamily: 'irisvr-bold',
            fontSize: 19,
            margin: 0,
            color: 'black',
            textDecoration: 'none',
          }}
        >
          {headerText}
        </Link>
      </div>
    )
  }

  return (
    <div className="header-text-container">
      <p className="header-text">{headerText}</p>
    </div>
  )
}

const HeaderIcon = ({ file, headerTitle }) => {
  const linkedHeaderIcon = headerTitle === 'vr-headsets'

  if (linkedHeaderIcon) {
    return (
      <div className="header-icon-container" style={{ marginBottom: 20 }}>
        <Link to={'/supported-headsets'}>
          <IrisImg className="header-icon" file={file} alt="header-icon" />
        </Link>
      </div>
    )
  }

  return (
    <div className="header-icon-container">
      <IrisImg className="header-icon" file={file} alt="header-icon" />
    </div>
  )
}

const Integrations = ({
  headerTitle,
  headerIconName,
  headerText,
  integrations,
  images,
}) => (
  <div className={`integrations ${headerTitle}`}>
    <div className="header-container">
      <HeaderIcon file={images[headerIconName]} headerTitle={headerTitle} />
      <HeaderText headerTitle={headerTitle} headerText={headerText} />
    </div>
    <div className="integrations-list">
      {integrations.map((int, idx) => (
        <LinkedIntegrationIconContainer
          key={`integration-${idx}`}
          integration={int}
          images={images}
          headerTitle={headerTitle}
        />
      ))}
    </div>
  </div>
)

Integrations.propTypes = {
  headerTitle: PropTypes.string.isRequired,
  headerIconName: PropTypes.string.isRequired,
  headerText: PropTypes.string.isRequired,
  integrations: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
}

export default Integrations
