import React from 'react'
import PropTypes from 'prop-types'

const ScaleIcon = ({ fill }) => (
  <svg
    width="20px"
    height="20px"
    viewBox="0 0 20 20"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
  >
    <desc>Created with Sketch.</desc>
    <defs />
    <g
      id="Prospect-Prod-Page"
      stroke="none"
      strokeWidth="1"
      fill={fill}
      fillRule="evenodd"
    >
      <g
        id="Prospect-Product-Page_Desktop"
        transform="translate(-698.000000, -3979.000000)"
        fill={fill}
      >
        <g id="Group-9" transform="translate(698.000000, 3979.000000)">
          <path
            d="M1.5,20 L1.5,12.5 L0,12.5 L0,7 C0,5.9 0.9,5 2,5 L5,5 C6.1,5 7,5.9 7,7 L7,12.5 L5.5,12.5 L5.5,20 L1.5,20 Z M3.5,4 C4.61,4 5.5,3.11 5.5,2 C5.5,0.89 4.61,0 3.5,0 C2.39,0 1.5,0.89 1.5,2 C1.5,3.11 2.39,4 3.5,4 Z"
            id="Shape"
            fillRule="nonzero"
          />
          <polygon
            id="Path-2"
            points="10.65 12.35 13.4 12.35 13.4 9.05 15.5981002 9.05 15.5981002 12.3500005 18.35 12.3500005 18.35 7.95 20 7.95 14.5 3 9 7.95 10.65 7.95"
          />
        </g>
      </g>
    </g>
  </svg>
)

ScaleIcon.propTypes = {
  fill: PropTypes.string.isRequired,
}

export default ScaleIcon
