import comfortIcon from '../../../../../images/prospect/irisvr-guarantee/vr-headset.svg'
import dataIcon from '../../../../../images/prospect/irisvr-guarantee/bim-icon.svg'
import { Filenames } from '../../filenames'

export default [
  {
    svg: comfortIcon,
    title: 'Comfortable',
    subtitle: 'Adheres to framerate and locomotion best practices.',
  },
  {
    svg: dataIcon,
    title: 'Data Rich',
    subtitle: 'Native support for BIM and model metadata.',
  },
  {
    filename: Filenames.pathIcon,
    title: 'Fast & Simple',
    subtitle: 'Supports large files out of the box in seconds.',
  },
  {
    filename: Filenames.premiumIcon,
    title: 'Premium',
    subtitle: 'Delivering world-class support and uptime.',
  },
]
