import { getWindowLocationOrigin } from '../../../utils/window'
import { generateMetaTags } from '../../../utils/helmet'

const title = 'Prospect - Immersive VR Walkthroughs | View 3D Models in VR'
const description =
  'Start a free 14-day trial now! Immersive VR walkthroughs in 1 click. Prospect converts Revit, Navisworks, Rhino, SketchUp, Navisworks 3D models into VR.'
const url = `${getWindowLocationOrigin()}/prospect`
const image =
  'https://s3.amazonaws.com/iris-opengraph-images/ImgTag_Prospect.jpg'
const imageWidth = 1200
const imageHeight = 697

export default generateMetaTags({
  title,
  description,
  image,
  url,
  imageWidth,
  imageHeight,
})
