export const Filenames = {
  splashBackground: 'splash-background',
  upgradeDesignWorkflow: 'upgrade-design-workflow',
  openQuote: 'open-quote',
  closedQuote: 'closed-quote',
  e4hLogo: 'e4h',
  hdrLogo: 'hdr',

  sketchupIcon: 'sketchup',
  revitIcon: 'revit',
  rhinoIcon: 'rhino',
  fbxIcon: 'fbx',
  navisworksIcon: 'navisworks',
  objIcon: 'obj',
  htcIcon: 'htc',
  oculusIcon: 'oculus',
  windowsMrIcon: 'windows-mr',

  guarantee: 'guarantee',
  pathIcon: 'path-7',
  premiumIcon: 'premium',

  filesIcon: 'files',
  vrHeadsetsIcon: 'vr-headsets',

  startTrialProspectLogo: 'start-trial-prospect',
}
