import React, { Component } from 'react'
import PropTypes from 'prop-types'
import FeatureListContainer from './FeatureListContainer'
import GifSlideshowContainer from './GifSlideshowContainer'

class FeaturesContainer extends Component {
  state = {
    actionType: '',
    autoplaySpeed: 6000,
    selectedIndex: -1,
  }

  // used by GifSlideshowContainer to communicate
  // with FeatureListContainer
  selectAdjacentFeature = type =>
    this.setState({
      actionType: type,
    })

  // used by FeatureListContainer to communicate
  // with GifSlideshowContainer
  selectIndex = i =>
    this.setState({
      selectedIndex: i,
      actionType: '', // reset to prevent continuous render
    })

  render() {
    const { autoplaySpeed, selectedIndex, actionType } = this.state
    const { mediaType, content, category, width } = this.props

    const desktopType =
      width > 1100 && width < 1280 ? 'small-desktop' : 'large-desktop'

    return (
      <div
        className={`features-container ${mediaType}-features-container ${category} ${desktopType}`}
      >
        <GifSlideshowContainer
          title={content.title}
          mediaType={mediaType}
          features={content.features}
          selectedIndex={selectedIndex}
          selectAdjacentFeature={this.selectAdjacentFeature}
        />
        <FeatureListContainer
          {...content}
          mediaType={mediaType}
          selectIndex={this.selectIndex}
          actionType={actionType}
          autoplaySpeed={autoplaySpeed}
        />
      </div>
    )
  }
}

FeaturesContainer.propTypes = {
  content: PropTypes.shape({}).isRequired,
  category: PropTypes.string.isRequired,
  mediaType: PropTypes.string.isRequired,
  width: PropTypes.number.isRequired,
}

export default FeaturesContainer
