import React from 'react'
import PropTypes from 'prop-types'
import { IrisImg } from '../../../../../Shared'

const FeatureContainer = ({ images, svg: SVG, filename, title, subtitle }) => (
  <div className="feature-container">
    <div className="icon-container">
      {SVG ? <SVG className="feature-icon" /> : ''}
      {filename && (
        <IrisImg className="feature-icon" file={images[filename]} alt={title} />
      )}
    </div>
    <div className="title-container">
      <p className="feature-title">{title}</p>
    </div>
    <div className="subtitle-container">
      <p className="feature-subtitle">{subtitle}</p>
    </div>
  </div>
)

FeatureContainer.propTypes = {
  icon: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  subtitle: PropTypes.string.isRequired,
}

export default FeatureContainer
