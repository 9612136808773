import React from 'react'
import PropTypes from 'prop-types'
import Testimonial from '../../elements/Testimonial'
import metadata from './metadata'

const SecondTestimonial = ({ images, mediaType }) => (
  <div className="testimonial-section-container">
    <div className="content-container">
      <Testimonial {...metadata} images={images} mediaType={mediaType}>
        <p>
          Ease of use. Intuitiveness. Simplicity. Quality of imagery. It’s not
          trying to be anything other than a very nice VR engine.{' '}
          <span className="emphasis">
            It does what it says on the box... and very well.
          </span>
        </p>
      </Testimonial>
    </div>
  </div>
)

SecondTestimonial.propTypes = {
  mediaType: PropTypes.string.isRequired,
}

export default SecondTestimonial
