import React from 'react'
import { IrisCTALinkButton } from '../../../../../Shared'
import AccentBorder from '../../../elements/AccentBorder'
import { getSignupUrl } from '../../../../../../utils/url'

const DesktopSplash = () => (
  <div className="desktop-splash-content-container">
    <div className="desktop-splash-content">
      <AccentBorder color="light-blue" />
      <div className="title-container">
        <p className="title">Instant 3D to VR</p>
      </div>
      <div className="desc-container">
        <p className="desc">Communicate Design Intent in 1:1 Scale</p>
      </div>
      <div className="button-container">
        <IrisCTALinkButton href={getSignupUrl()} cta="Free Trial" />
      </div>
    </div>
  </div>
)

export default DesktopSplash
