import React, { Component } from 'react'
import PropTypes from 'prop-types'
import Slider from 'react-slick'
import CustomArrow from './CustomArrow'
import GuestViewerAsterisk from '../GuestViewerAsterisk'
// import NextArrow from 'assets/images/prospect/vr-tools/nextArrow.svg';
// import PrevArrow from 'assets/images/prospect/vr-tools/prevArrow.svg';

class GifSlideshowContainer extends Component {
  state = {
    settings: {
      autoplay: false,
      dots: true,
      pauseOnDotsHover: true,
      infinite: true,
      speed: 350,
      slidesToShow: 1,
      slidesToScroll: 1,
      draggable: false,
      lazyLoad: 'progressive',
      pauseOnHover: false,
      prevArrow: (
        <CustomArrow
          customOnClick={() => this.selectAdjacentFeature('prev')}
          type="before"
        />
      ),
      nextArrow: (
        <CustomArrow
          customOnClick={() => this.selectAdjacentFeature('next')}
          type="next"
        />
      ),
    },
  }

  componentWillReceiveProps(nextProps) {
    // This should trigger if someone clicks on the text
    // from the FeatureListContainer
    const featureIsSelected = nextProps.selectedIndex > -1
    if (featureIsSelected) {
      this.slider.innerSlider.slickGoTo(nextProps.selectedIndex)
    }
  }

  // Slider will handle transitioning slide;
  // make sure feature list text also transitions
  selectAdjacentFeature = type => {
    this.slider.innerSlider.pause()
    this.props.selectAdjacentFeature(type)
  }

  render() {
    const { title, features, mediaType } = this.props
    return (
      <div
        className={`gif-slideshow-container ${mediaType}-gif-slideshow-container`}
      >
        <Slider ref={slider => (this.slider = slider)} {...this.state.settings}>
          {// react-slick library does not allow the
          // slide to be its own component, as it needs
          // to apply custom classes, HTML targets.
          features.map(feature => (
            <div key={feature.name} className="ivr-slide">
              {// Header for mobile view
              mediaType === 'mobile' ||
              mediaType === 'tablet' ||
              mediaType === 'laptop' ? (
                <div key={feature.name} className="feature-container">
                  <div className="feature-icon-container">
                    <feature.icon fill="#4D9DE3" />
                  </div>
                  <p className="feature-name">{feature.name}</p>
                </div>
              ) : (
                ''
              )}

              <div className="feature-gif-container">
                <img
                  alt={feature.name}
                  className="feature-gif"
                  src={feature.gifUrl}
                />
              </div>
            </div>
          ))}
        </Slider>
        {title === 'Collaboration' && mediaType !== 'desktop' && (
          <GuestViewerAsterisk />
        )}
      </div>
    )
  }
}

GifSlideshowContainer.propTypes = {
  title: PropTypes.string.isRequired,
  features: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  selectedIndex: PropTypes.number.isRequired,
  selectAdjacentFeature: PropTypes.func.isRequired,
  mediaType: PropTypes.string.isRequired,
}

export default GifSlideshowContainer
