import React from 'react'
import PropTypes from 'prop-types'

const AnnotationsIcon = ({ fill }) => (
  <svg
    width="18px"
    height="18px"
    viewBox="0 0 18 18"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
  >
    <desc>Created with Sketch.</desc>
    <defs />
    <g
      id="Prospect-Prod-Page"
      stroke="none"
      strokeWidth="1"
      fill="none"
      fillRule="evenodd"
    >
      <g
        id="Prospect-Product-Page_Desktop"
        transform="translate(-699.000000, -3785.000000)"
      >
        <g
          id="baseline-border_color-24px"
          transform="translate(694.000000, 3785.000000)"
        >
          <path
            d="M19.6117647,7.41176471 L15.5882353,3.38823529 L5,13.9764706 L5,18 L9.02352941,18 L19.6117647,7.41176471 Z M22.6823529,4.23529412 C23.1058824,3.81176471 23.1058824,3.17647059 22.6823529,2.75294118 L20.2470588,0.317647059 C19.8235294,-0.105882353 19.1882353,-0.105882353 18.7647059,0.317647059 L16.6470588,2.43529412 L20.6705882,6.35294118 L22.6823529,4.23529412 Z"
            id="Shape"
            fill={fill}
            fillRule="nonzero"
          />
          <polygon id="Shape" points="0 0 25 0 25 25 0 25" />
        </g>
      </g>
    </g>
  </svg>
)

AnnotationsIcon.propTypes = {
  fill: PropTypes.string.isRequired,
}

export default AnnotationsIcon
