/* eslint-disable import/first */
import AnnotationsIcon from './SVGComponents/AnnotationsIcon'
import AnnotationsGif from '../../../../../images/prospect/gif/annotations.gif'
import GuestViewerIcon from './SVGComponents/GuestViewerIcon'
import GuestViewerGif from '../../../../../images/prospect/gif/guest-viewer.gif'
import InspectIcon from './SVGComponents/InspectIcon'
import InspectGif from '../../../../../images/prospect/gif/inspect-element.gif'
import LayersIcon from './SVGComponents/LayersIcon'
import LayersGif from '../../../../../images/prospect/gif/layers.gif'
import MultiuserIcon from './SVGComponents/MultiuserIcon'
import MultiuserGif from '../../../../../images/prospect/gif/multi-user.gif'
import NoHmdIcon from './SVGComponents/NoHmdIcon'
import NoHmdGif from '../../../../../images/prospect/gif/no-hmd.gif'
import PresenterIcon from './SVGComponents/PresenterIcon'
import PresenterGif from '../../../../../images/prospect/gif/presenter.gif'
import ScaleIcon from './SVGComponents/ScaleIcon'
import SectionsGif from '../../../../../images/prospect/gif/scale-model-mode.gif'
import ScreenshotIcon from './SVGComponents/ScreenshotIcon'
import ScreenshotGif from '../../../../../images/prospect/gif/screenshot.gif'
import SunIcon from './SVGComponents/SunIcon'
import SunGif from '../../../../../images/prospect/gif/sun-settings.gif'
import TapeIcon from './SVGComponents/TapeIcon'
import TapeGif from '../../../../../images/prospect/gif/tape-measure.gif'
import ViewpointsIcon from './SVGComponents/ViewpointsIcon'
import ViewpointsGif from '../../../../../images/prospect/gif/viewpoints.gif'

export default {
  designReview: {
    title: 'Immersive Design Review',
    description:
      'Equip your team with the most comprehensive VR toolkit for immersive design review.',
    features: [
      {
        icon: AnnotationsIcon,
        name: 'Annotations',
        gifUrl: AnnotationsGif,
      },
      {
        icon: LayersIcon,
        name: 'Layers',
        gifUrl: LayersGif,
      },
      {
        icon: InspectIcon,
        name: 'Inspect Element',
        gifUrl: InspectGif,
      },
      {
        icon: TapeIcon,
        name: 'Tape Measure',
        gifUrl: TapeGif,
      },
      {
        icon: ScaleIcon,
        name: 'Scale Model Mode',
        gifUrl: SectionsGif,
      },
      {
        icon: SunIcon,
        name: 'Sun Studies',
        gifUrl: SunGif,
      },
    ],
  },
  collaboration: {
    title: 'Collaboration',
    description:
      'Choose from a variety of immersive collaboration tools in Prospect, and keep everyone on the same page.',
    features: [
      {
        icon: MultiuserIcon,
        name: 'Multiuser Meetings',
        gifUrl: MultiuserGif,
      },
      {
        icon: ScreenshotIcon,
        name: 'Screenshots',
        gifUrl: ScreenshotGif,
      },
      {
        icon: ViewpointsIcon,
        name: 'Viewpoints',
        gifUrl: ViewpointsGif,
      },
      {
        icon: NoHmdIcon,
        name: 'No Headset Mode',
        gifUrl: NoHmdGif,
      },
      {
        icon: PresenterIcon,
        name: 'Presenter Controls',
        gifUrl: PresenterGif,
      },
      {
        icon: GuestViewerIcon,
        name: 'Guest Viewer*',
        gifUrl: GuestViewerGif,
      },
    ],
  },
}
