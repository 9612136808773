import React from 'react'
import { graphql } from 'gatsby'
import { Helmet } from '../components/Shared'
import Prospect, { helmetProps } from '../components/Products/Prospect'
import { Filenames } from '../components/Products/Prospect/filenames'
import { arrayToObject } from '../utils/image'

export const query = graphql`
  {
    splashBackground: file(
      relativeDirectory: { eq: "prospect" }
      name: { eq: "splash-background" }
    ) {
      id
      name
      childImageSharp {
        fixed(height: 600) {
          ...GatsbyImageSharpFixed_withWebp_noBase64
        }
      }
    }
    upgradeDesignWorkflow: file(
      relativeDirectory: { eq: "prospect" }
      name: { eq: "upgrade-design-workflow" }
    ) {
      id
      name
      childImageSharp {
        fixed(width: 522) {
          ...GatsbyImageSharpFixed_withWebp_noBase64
        }
      }
    }
    openQuote: file(
      relativeDirectory: { eq: "prospect/testimonial" }
      name: { eq: "open-quote" }
    ) {
      id
      name
      childImageSharp {
        fixed(width: 34) {
          ...GatsbyImageSharpFixed_withWebp_noBase64
        }
      }
    }
    closedQuote: file(
      relativeDirectory: { eq: "prospect/testimonial" }
      name: { eq: "close-quote" }
    ) {
      id
      name
      childImageSharp {
        fixed(width: 34) {
          ...GatsbyImageSharpFixed_withWebp_noBase64
        }
      }
    }
    e4hLogo: file(
      relativeDirectory: { eq: "prospect/testimonial" }
      name: { eq: "e4h" }
    ) {
      id
      name
      childImageSharp {
        fixed(width: 70) {
          ...GatsbyImageSharpFixed_withWebp_noBase64
        }
      }
    }
    filesIcon: file(
      relativeDirectory: { eq: "prospect/bim-video" }
      name: { eq: "files" }
    ) {
      id
      name
      childImageSharp {
        fixed(width: 38) {
          ...GatsbyImageSharpFixed_withWebp_noBase64
        }
      }
    }
    hdrLogo: file(
      relativeDirectory: { eq: "prospect/testimonial" }
      name: { eq: "hdr" }
    ) {
      id
      name
      childImageSharp {
        fixed(height: 35) {
          ...GatsbyImageSharpFixed_withWebp_noBase64
        }
      }
    }

    sketchupIcon: file(
      relativeDirectory: { eq: "prospect/integrations" }
      name: { eq: "sketchup" }
    ) {
      id
      name
      childImageSharp {
        fixed(height: 44) {
          ...GatsbyImageSharpFixed_withWebp_noBase64
        }
      }
    }
    revitIcon: file(
      relativeDirectory: { eq: "prospect/integrations" }
      name: { eq: "revit" }
    ) {
      id
      name
      childImageSharp {
        fixed(height: 44) {
          ...GatsbyImageSharpFixed_withWebp_noBase64
        }
      }
    }
    rhinoIcon: file(
      relativeDirectory: { eq: "prospect/integrations" }
      name: { eq: "rhino" }
    ) {
      id
      name
      childImageSharp {
        fixed(height: 44) {
          ...GatsbyImageSharpFixed_withWebp_noBase64
        }
      }
    }
    fbxIcon: file(
      relativeDirectory: { eq: "prospect/integrations" }
      name: { eq: "fbx" }
    ) {
      id
      name
      childImageSharp {
        fixed(height: 44) {
          ...GatsbyImageSharpFixed_withWebp_noBase64
        }
      }
    }
    navisworksIcon: file(
      relativeDirectory: { eq: "prospect/integrations" }
      name: { eq: "navisworks" }
    ) {
      id
      name
      childImageSharp {
        fixed(height: 44) {
          ...GatsbyImageSharpFixed_withWebp_noBase64
        }
      }
    }
    objIcon: file(
      relativeDirectory: { eq: "prospect/integrations" }
      name: { eq: "obj" }
    ) {
      id
      name
      childImageSharp {
        fixed(height: 44) {
          ...GatsbyImageSharpFixed_withWebp_noBase64
        }
      }
    }
    htcIcon: file(
      relativeDirectory: { eq: "prospect/integrations" }
      name: { eq: "htc" }
    ) {
      id
      name
      childImageSharp {
        fixed(height: 44) {
          ...GatsbyImageSharpFixed_withWebp_noBase64
        }
      }
    }
    oculusIcon: file(
      relativeDirectory: { eq: "prospect/integrations" }
      name: { eq: "oculus" }
    ) {
      id
      name
      childImageSharp {
        fixed(height: 44) {
          ...GatsbyImageSharpFixed_withWebp_noBase64
        }
      }
    }
    windowsMrIcon: file(
      relativeDirectory: { eq: "prospect/integrations" }
      name: { eq: "windows-mr" }
    ) {
      id
      name
      childImageSharp {
        fixed(height: 44) {
          ...GatsbyImageSharpFixed_withWebp_noBase64
        }
      }
    }
    vrHeadsetsIcon: file(
      relativeDirectory: { eq: "prospect/integrations" }
      name: { eq: "vr-headsets" }
    ) {
      id
      name
      childImageSharp {
        fixed(height: 38) {
          ...GatsbyImageSharpFixed_withWebp_noBase64
        }
      }
    }

    ennead: file(
      relativeDirectory: { eq: "logos/trusted/main" }
      name: { eq: "ennead" }
    ) {
      id
      name
      childImageSharp {
        fixed(width: 111) {
          ...GatsbyImageSharpFixed_withWebp_noBase64
        }
      }
    }
    miele: file(
      relativeDirectory: { eq: "logos/trusted/main" }
      name: { eq: "miele" }
    ) {
      id
      name
      childImageSharp {
        fixed(width: 68) {
          ...GatsbyImageSharpFixed_withWebp_noBase64
        }
      }
    }
    corgan: file(
      relativeDirectory: { eq: "logos/trusted/main" }
      name: { eq: "corgan" }
    ) {
      id
      name
      childImageSharp {
        fixed(width: 136) {
          ...GatsbyImageSharpFixed_withWebp_noBase64
        }
      }
    }
    mortenson: file(
      relativeDirectory: { eq: "logos/trusted/main" }
      name: { eq: "mortenson" }
    ) {
      id
      name
      childImageSharp {
        fixed(width: 87) {
          ...GatsbyImageSharpFixed_withWebp_noBase64
        }
      }
    }
    shop: file(
      relativeDirectory: { eq: "logos/trusted/main" }
      name: { eq: "shop" }
    ) {
      id
      name
      childImageSharp {
        fixed(width: 68) {
          ...GatsbyImageSharpFixed_withWebp_noBase64
        }
      }
    }
    hmc: file(
      relativeDirectory: { eq: "logos/trusted/main" }
      name: { eq: "hmc" }
    ) {
      id
      name
      childImageSharp {
        fluid(maxHeight: 22, maxWidth: 206) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }
    bv: file(
      relativeDirectory: { eq: "logos/trusted/main" }
      name: { eq: "bv" }
    ) {
      id
      name
      childImageSharp {
        fixed(width: 140) {
          ...GatsbyImageSharpFixed_withWebp_noBase64
        }
      }
    }

    guarantee: file(
      relativeDirectory: { eq: "prospect/irisvr-guarantee" }
      name: { eq: "guarantee" }
    ) {
      id
      name
      childImageSharp {
        fluid(maxWidth: 484, maxHeight: 459) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }

    pathIcon: file(
      relativeDirectory: { eq: "prospect/irisvr-guarantee" }
      name: { eq: "path-7" }
    ) {
      id
      name
      childImageSharp {
        fixed(height: 36) {
          ...GatsbyImageSharpFixed_withWebp_noBase64
        }
      }
    }

    premiumIcon: file(
      relativeDirectory: { eq: "prospect/irisvr-guarantee" }
      name: { eq: "premium" }
    ) {
      id
      name
      childImageSharp {
        fixed(height: 36) {
          ...GatsbyImageSharpFixed_withWebp_noBase64
        }
      }
    }

    startTrialProspectLogo: file(
      relativeDirectory: { eq: "trial" }
      name: { eq: "start-trial-prospect" }
    ) {
      id
      name
      childImageSharp {
        fixed(width: 225) {
          ...GatsbyImageSharpFixed_withWebp_noBase64
        }
      }
    }
  }
`

export default React.memo(({ data }) => (
  <>
    <Helmet {...helmetProps} />
    <Prospect
      logoBannerImages={arrayToObject([
        data.ennead,
        data.miele,
        data.corgan,
        data.mortenson,
        data.shop,
        data.hmc,
        data.bv,
      ])}
      images={{
        [Filenames.splashBackground]: data.splashBackground,
        [Filenames.upgradeDesignWorkflow]: data.upgradeDesignWorkflow,
        [Filenames.e4hLogo]: data.e4hLogo,
        [Filenames.filesIcon]: data.filesIcon,
        [Filenames.vrHeadsetsIcon]: data.vrHeadsetsIcon,
        [Filenames.hdrLogo]: data.hdrLogo,
        [Filenames.openQuote]: data.openQuote,
        [Filenames.closedQuote]: data.closedQuote,
        [Filenames.sketchupIcon]: data.sketchupIcon,
        [Filenames.revitIcon]: data.revitIcon,
        [Filenames.rhinoIcon]: data.rhinoIcon,
        [Filenames.fbxIcon]: data.fbxIcon,
        [Filenames.navisworksIcon]: data.navisworksIcon,
        [Filenames.objIcon]: data.objIcon,
        [Filenames.htcIcon]: data.htcIcon,
        [Filenames.oculusIcon]: data.oculusIcon,
        [Filenames.windowsMrIcon]: data.windowsMrIcon,
        [Filenames.guarantee]: data.guarantee,
        [Filenames.pathIcon]: data.pathIcon,
        [Filenames.premiumIcon]: data.premiumIcon,
        [Filenames.startTrialProspectLogo]: data.startTrialProspectLogo,
      }}
    />
  </>
))
