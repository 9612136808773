import React from 'react'
import BimIcon from '../../../../../images/prospect/bim-video/bim-icon.svg'
import GeometryIcon from '../../../../../images/prospect/bim-video/complex-geometry.svg'
import { Filenames } from '../../filenames'

export default [
  {
    filename: Filenames.filesIcon,
    text: 'Loads 3D files in seconds',
  },
  {
    svg: BimIcon,
    text: 'Imports native BIM metadata into VR',
  },
  {
    svg: GeometryIcon,
    text: 'Automatically optimizes geometry',
  },
]
